<template>
    <div class="m-online m-scroll" @scroll="handleScroll($event)">
        <div class="header">
            <img src="@/assets/icon_back@3x.png" alt="back" @click="$router.push('/en')">
            <p>Night Market</p>
        </div>
        <div class="header-bg" >
            <div class="online-top">
                <img class="ele1" src="@/assets/richmond/ele1.png" alt="">
                <img class="ele2" src="@/assets/richmond/ele2.png" alt="">
                <img class="ele3" src="@/assets/richmond/ele3.png" alt="">
                <div class="online-video">
                    <video-player
                        class="video-player vjs-custom-skin video"
                        ref="videoPlayer"
                        autoplay
                        playsinline
                        x5-playsinline
                        webkit-playsinline
                        :mtt-playsinline="true"
                        :x5-video-player-type="h5" 
                        x5-video-player-fullscreen
                        :options="playerOptions"
                        @pause="onPlayerPause($event)"
                    ></video-player>
                </div>
            </div>
        </div>
        <div class="cell">
            <img class="cell-left" src="@/assets/richmond/LOGO.png" alt="">
            <div class="cell-right" @click="$router.push('/en')">
                <span>Show ALL</span>
                <img src="@/assets/index/icon_genduo@2x.png" alt="more">
            </div>
        </div>
        <div class="online-goods">
            <goods-columns @cartClick="handleCart" :data="goodsList" :columns="2" :night="1" @visitClick="handleVisit"></goods-columns>
            <p class="search-no" v-if='noMore'>No More Products</p> 
        </div>
        <div class="online-foot">
            <div class="foot-btn" @click="$router.push('/nightmarketcoupons')">
                <img src="@/assets/richmond/2022-LOGO.png" alt="">
                <span>Free Coupon Entrance</span>
            </div>
        </div>
        <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading>

        <!-- 选择SKU 加入购物车 -->
        <m-sku title="Add to Cart" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goodsId="goodsId"></m-sku>

        <!-- 加入购物车动画 -->
        <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>

        <!-- 购物车浮窗 -->
        <transition name="van-fade">
            <CartFlex v-if="start" />
        </transition>
    </div>
</template>
<script>
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'

import MSku         from '@/components/en/m-sku.vue'
import GoodsColumns from '@/components/en/goods-columns-active.vue'
import MAnimate     from '@/components/en/cart-animate.vue'
import CartFlex     from '@/components/en/cart-flex.vue'


import { market_goods, user_retain, visit } from '@/api/en/index.js'
import { use_info } from "@/api/en/night_market.js"
import { parseTime } from '@/untils/js/common.js'

export default {
    components:{ GoodsColumns, MSku, MAnimate, CartFlex },
    data() {
        return {
            h5:'h5-page',
            playerOptions : {
                autoplay: false, //如果true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 导致视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                aspectRatio: '375:375', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                poster: require('@/assets/richmond/video.png'), //你的封面地址
                sources: [{
                type: "video/mp4",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                src: require('@/assets/richmond/video.mp4') //url地址
                }],
                width: document.documentElement.clientWidth, //播放器宽度
                notSupportedMessage: '视频加载失败', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true, // 是否显示全屏按钮
                    volumePanel: false, // 是否显示声音按钮
                },
            },
            loading:false,
            skuModal:false,
            start:false,
            start_dom:null,
            dotAnimate:false,
            cartNum:0,
            scrollTop:false,
            goodsList: [],
            goodsId: '',
            landing: '',
            noMore: false,
            page:1,
            start_time: '',
            end_time: '',
        }
    },
    created() {
        if (this.$route.query.mooby_token) {
            localStorage.setItem('token', this.$route.query.mooby_token)
            localStorage.setItem('token2', this.$route.query.mooby_token)
            // this.$router.push('/nightmarketcoupons')
        }
        this.start_time = parseTime(new Date().getTime())
        this.getActiveList()
        this.getLogin()
        this.$nextTick(() => {
            this.$refs.videoPlayer.player.play()
        })
    },
    methods: {
        // 内容滑动事件
        handleScroll(e){
        if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 0){
            if(!this.noMore){
                e.preventDefault()
                e.stopPropagation()
                this.loading = true
                this.page++
                this.getActiveList()
            }
        }
        
        },
        // 暂停播放
        onPlayerPause(player) {
            player.pause()
        },
        getActiveList() {
            // 获取热门单品列表
            market_goods({page: this.page}).then(res => {
                this.goodsList = this.goodsList.concat(res.data.data)
                if (res.data.data.length == 0) {
                    this.noMore = true
                }
            }).finally(() => {
                this.loading = false
            })
        },
        // 判断是否登录
        getLogin() {
            use_info().then(res => {
                if(res) {
                    this.start_time = parseTime(new Date().getTime())
                    // console.log(this.start_time)
                }
            })
        },
        handleVisit(id) {
            visit({goods_activity_id:id}).then()
        },
        // 商品列表购物车点击事件
        handleCart(goods,e){
            this.goodsId = goods
            this.skuModal  = true
            this.start_dom = e 
        },
        // sku 弹窗加入购物车成功回调
        handleCartSuccess(){
            this.skuModal = false
            let timer = setTimeout( () => {
                this.start  = true
                this.dotAnimate = true 
                this.cartNum ++

                let timer1 = setTimeout(()=> {
                this.dotAnimate = false
                clearTimeout(timer1)
                },400)

                clearTimeout(timer)
            },300)
        },
        // 加入购物车动画结束
        handleCartEnd(){
            this.start    = false
        }
    },
    // 用户留存时间
    beforeRouteLeave(to, from, next) {
        // this.end_time = parseTime(new Date().getTime())
        // user_retain({start_time:this.start_time,end_time:this.end_time}).then()
        next()
    }
};
</script>
<style scoped lang="less">
    .m-online {
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        background: #FBEF00;
        .header {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 44px;
            background-color: #fff;
            padding: 0 12px 0 16px;
            display: flex;
            align-items: center;
            z-index: 999;
            &>img:nth-of-type(1){
                width: 5vw;
                height: 4.5vw;
                position: relative;
                z-index: 8;
            }
            &>p {
                font-size:4.26667vw;
                font-weight:400;
                color:#333;
                line-height:6.66667vw;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                text-align: center;
                line-height: 11.73333vw;
                padding: 0 21.33333vw;
            }
        }
        .header-bg {
            width: 100%;
            height: 300px;
            background: url('../../../assets/richmond/top-bg.png') no-repeat;
            background-size:100% 100%;
            overflow: hidden;
            img {
                width: 100%;
            }
            .online-top {
                position: relative;
                .ele1 {
                    width: 56px;
                    height: 47px;
                    position: absolute;
                    bottom: -19px;
                    right: -13px;
                    z-index: 1;
                }
                .ele2 {
                    width: 67px;
                    height: 86px;
                    position: absolute;
                    bottom: 48px;
                    left: 0;
                    z-index: 1;
                }
                .ele3 {
                    width: 56px;
                    height: 78px;
                    position: absolute;
                    top: -9px;
                    right: 0;
                    z-index: 1;
                }
                .online-video {
                    width: 338px;
                    height: 225px;
                    margin: 56px auto 0;
                    border-radius: 10px;
                    border: 2px solid #000;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #000;
                    .video-player {
                        width: 99%;
                        height: 99%;
                        overflow: hidden;
                        /deep/.vjs-tech{object-fit: cover;width: 100%;height: 100%;}
                        /deep/.vjs-poster{background-size: cover;}
                        /deep/ .video-js.vjs-fluid {
                            height: 100%;
                            padding: 0;
                        }
                        /deep/ .vjs-custom-skin > .video-js .vjs-big-play-button {
                            width: 2em;
                            left: 56%;
                            border-radius: 50%;
                            border: none;
                            z-index: 999;
                        }
                    }
                }
            }
        }
        .cell {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 12px;
            margin: 16px 0 10px;
            .cell-left {
                width: 162px;
                height: 34px;
            }
            .cell-right {
                font-size: 14px;
                color: #666;
                display: flex;
                align-items: center;
                img {
                    width: 14px;
                    height: 14px;
                    vertical-align: middle;
                    font-size: 0;
                }
            }
        }
        .online-goods {
            margin-bottom: 84px;
            /deep/ .m-goods-columns {
                padding: 0;
                .bg {
                    margin-bottom: 5px;
                    .goods-columns-2 {
                        width: 184px;
                        img {
                            width: 184px;
                            height: 184px;
                        }
                        .goods-img {
                            width: 184px;
                            height: 184px;
                        }
                        .goods-price {
                            img {
                                width: 40px;
                                height: 40px;
                            }
                        }
                    }
                }
            }
            .search-no{line-height:50px;font-size:12px;color:#888;text-align:center}
        }
        .online-foot {
            width: 100%;
            height: 84px;
            position: fixed;
            z-index: 10;
            bottom: 0;
            left: 0;
            display: flex;
            // align-items: center;
            justify-content: center;
            background: #1F8FFF;
            border-radius: 20px 20px 0 0;
            .foot-btn {
                width: 329px;
                height: 44px;
                border-radius: 22px;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 88.07%), #FFEE11;
                text-align: center;
                line-height: 44px;
                margin-top: 15px;
                span {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    color: #000;
                    margin-left: 12px;
                }
                img {
                    width: 35px;
                    height: 35px;
                    vertical-align: middle;
                }
            }
        }
        
        
    }
</style>